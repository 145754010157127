import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import { cloneDeep, findIndex } from 'lodash';
import mutationSaveEmotionalTriggersTable from './graphql/saveEmotionalTriggersTable';
import queryGetEmotionalTriggersTableList from './graphql/getEmotionalTriggersTableList';
import { addOriginalColorsInData } from './helpers';
import { setActiveTriggersTable } from '../Actions/setActiveTriggersTable';

export const updateQueryTriggersTableList = (projectId, dispatchSetActiveTriggersTable) => (
  cache,
  { data: { saveEmotionalTriggersTable } },
) => {
  const triggersTableList = cloneDeep(
    cache.readQuery({
      query: queryGetEmotionalTriggersTableList,
      variables: { projectId },
    }).getEmotionalTriggersTableList.emotionalTriggersTables,
  );
  let dataToReplace;

  const foundModelIndex = findIndex(triggersTableList, {
    id: saveEmotionalTriggersTable.emotionalTriggersTable.id,
  });

  if (foundModelIndex === -1) {
    const saveTriggersTableExtended = saveEmotionalTriggersTable.emotionalTriggersTable;
    dataToReplace = triggersTableList.concat(saveTriggersTableExtended);
  } else {
    if (triggersTableList[foundModelIndex].isDefault !== saveEmotionalTriggersTable.emotionalTriggersTable.isDefault) {
      const oldDefaultTriggersTable = findIndex(triggersTableList, {
        isDefault: true,
      });
      triggersTableList[oldDefaultTriggersTable] = {
        ...triggersTableList[oldDefaultTriggersTable],
        isDefault: false,
      };
    }
    triggersTableList[foundModelIndex] = {
      ...triggersTableList[foundModelIndex],
      ...saveEmotionalTriggersTable.emotionalTriggersTable,
    };
    dataToReplace = triggersTableList;
    dispatchSetActiveTriggersTable(triggersTableList[foundModelIndex]);
  }

  cache.writeQuery({
    query: queryGetEmotionalTriggersTableList,
    variables: { projectId },
    data: { getEmotionalTriggersTableList: { emotionalTriggersTables: dataToReplace } },
  });
};

class MutationSaveTriggersTable extends React.Component {
  render() {
    const { currentProject, variables, children, callBackAction, dispatchSetActiveTriggersTable } = this.props;

    const queryForUpdate = updateQueryTriggersTableList(currentProject.id, dispatchSetActiveTriggersTable);
    const dataVariablesWithColors = addOriginalColorsInData(variables?.data);

    return (
      <Mutation
        mutation={mutationSaveEmotionalTriggersTable}
        update={(cache, saveEmotionalTriggersTable) => queryForUpdate(cache, saveEmotionalTriggersTable)}
        refetchQueries={['getEmotionalTriggersTableList']}
      >
        {saveEmotionalTriggersTable =>
          React.Children.map(children, child => {
            const propsToPass = {
              onClick: () => {
                saveEmotionalTriggersTable({
                  variables: { ...variables, data: dataVariablesWithColors },
                });
                callBackAction();
              },
            };
            return React.cloneElement(child, propsToPass);
          })
        }
      </Mutation>
    );
  }
}

MutationSaveTriggersTable.propTypes = {
  currentProject: PropTypes.objectOf({
    demo: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
  callBackAction: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  variables: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
            percentage: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
  }),
  dispatchSetActiveTriggersTable: PropTypes.func.isRequired,
};

MutationSaveTriggersTable.defaultProps = {
  callBackAction: () => null,
  variables: {
    data: [],
  },
};

const mapStateToProps = state => ({
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetActiveTriggersTable: triggersTable => {
    dispatch(setActiveTriggersTable(triggersTable));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MutationSaveTriggersTable);
