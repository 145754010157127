import gql from 'graphql-tag';

const queryGetEmotionalTriggersTableList = gql`
  query getEmotionalTriggersTableList($projectId: Int!) {
    getEmotionalTriggersTableList(projectId: $projectId) {
      emotionalTriggersTables {
        id
        title
        description
        isDefault
        createdBy {
          email
          firstName
          lastName
        }
        settings {
          analysisAxis
          itemsToDisplay {
            name
            key
          }
          calculationCriteria
          significanceThreshold
          range {
            startDate
            endDate
          }
          thematics {
            thematic
            subThematic
            subSubThematic
            joinSpecify
          }
          criteriaKeys {
            name
            values
          }
        }
        customizations {
          originalName
          name
          originalColor
          color
          items {
            originalName
            name
          }
          deleted
          masked
        }
        data {
          originalName
          name
          originalColor
          color
          items {
            originalName
            name
            percentage
            value
          }
          value
          deleted
          masked
        }
      }
    }
  }
`;

export default queryGetEmotionalTriggersTableList;
