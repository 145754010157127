import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
// import { transformItemFiltersToSettings } from '_Providers/QFilterOptionsProvider/optionProviderFunctions';
import getEmotionalTriggersTableList from './graphql/getEmotionalTriggersTableList';
import { DispatchDataToStore } from '../../Header/Services/api/DispatchDataToStore';
import { setProjectTriggersTables } from '../Actions/setProjectTriggersTables';

export const SETTINGS_TYPES = {
  CARDS: 'cards',
  COLUMNS: 'columns',
};

class QueryEmotionalTriggersTableList extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { projectThematics } = this.props;
    if (nextProps.projectThematics !== projectThematics) {
      return true;
    }

    return false;
  }

  render() {
    const { projectThematics, currentProjectId, dispatchSetProjectTriggersTables } = this.props;
    return projectThematics && currentProjectId ? (
      <Query
        query={getEmotionalTriggersTableList}
        variables={{
          projectId: currentProjectId,
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, networkStatus }) => {
          if (loading || networkStatus === 4) return false;
          if (error) {
            return false;
          }
          const cloneData = data;
          const triggersTables = cloneData.getEmotionalTriggersTableList.emotionalTriggersTables;
          return <DispatchDataToStore data={data} action={() => dispatchSetProjectTriggersTables(triggersTables)} />;
        }}
      </Query>
    ) : null;
  }
}

QueryEmotionalTriggersTableList.propTypes = {
  currentProjectId: PropTypes.number.isRequired,
  dispatchSetProjectTriggersTables: PropTypes.func.isRequired,
  projectThematics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      values:
        PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            values:
              PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.number.isRequired,
                  label: PropTypes.string.isRequired,
                  values: null,
                }),
              ) || null,
          }).isRequired,
        ) || null,
    }).isRequired,
  ).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetProjectTriggersTables: data => {
      dispatch(setProjectTriggersTables(data));
    },
  };
}

function mapStateToProps(state) {
  return {
    currentProjectId: get(state, ['projects', 'currentProject', 'id']),
    projectThematics: get(state, ['filters', 'projectFilters', 'thematics']),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('filters')(QueryEmotionalTriggersTableList));
