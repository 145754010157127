import gql from 'graphql-tag';

const mutationSaveEmotionalTriggersTable = gql`
  mutation saveEmotionalTriggersTable(
    $projectId: Int!
    $id: Int
    $title: String!
    $description: String
    $isDefault: Boolean = false
    $computeTable: Boolean = false
    $analysisAxis: EmotionalTriggersAnalysisAxis!
    $itemsToDisplay: ItemsToDisplayInput!
    $calculationCriteria: EmotionalTriggersCalculationCriteria!
    $significanceThreshold: Float!
    $range: DateRangeInput!
    $thematics: [ThematicInput!]!
    $criteriaKeys: [CriteriaKey!]!
    $data: [EmotionalTriggersTableColumnInput!]
    $customizations: [ColumnCustomizationInput!]
  ) {
    saveEmotionalTriggersTable(
      projectId: $projectId
      id: $id
      title: $title
      description: $description
      isDefault: $isDefault
      computeTable: $computeTable
      analysisAxis: $analysisAxis
      itemsToDisplay: $itemsToDisplay
      calculationCriteria: $calculationCriteria
      significanceThreshold: $significanceThreshold
      range: $range
      thematics: $thematics
      criteriaKeys: $criteriaKeys
      data: $data
      customizations: $customizations
    ) {
      errors
      emotionalTriggersTable {
        id
        title
        description
        isDefault
        createdBy {
          email
          firstName
          lastName
        }
        settings {
          analysisAxis
          calculationCriteria
          itemsToDisplay {
            name
            key
          }
          significanceThreshold
          range {
            startDate
            endDate
          }
          thematics {
            thematic
            subThematic
            subSubThematic
            joinSpecify
          }
          criteriaKeys {
            name
            values
          }
        }
        data {
          color
          originalColor
          name
          originalName
          items {
            name
            originalName
            percentage
            value
          }
          value
          deleted
          masked
        }
        customizations {
          name
          originalName
          color
          originalColor
          items {
            name
            originalName
          }
          deleted
          masked
        }
      }
    }
  }
`;

export default mutationSaveEmotionalTriggersTable;
