import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Label } from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import Icon from '_Components/Icons/Icon';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import queryGetEmotionalTriggersTable from '_Resources/Triggers/Services/graphql/getEmotionalTriggersTable';
import { setActiveTriggersTable } from '_Resources/Triggers/Actions/setActiveTriggersTable';
import { setActiveTriggersOnlySelectedThematics } from '_Resources/Triggers/Actions/setActiveTriggersOnlySelectedThematics';
import { grayShades } from '../../../styles/abstracts/colors';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelSpan = styled(Label)`
  font-size: 0.9rem;
  color: ${grayShades.g800};
`;

const SettingDiv = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin: 2em;
  font-weight: bold;
  width: 16rem;
  margin-right: 2.5rem;
`;

const StyledSpan = styled.span`
  margin-left: 0.5em;
  position: relative;
  &:hover > div {
  visibility: visible;
`;

const TooltipThematic = styled.div`
  visibility: hidden;
  max-width: 20em;
  position: absolute;
  top: 27px;
  right: -6px;
  z-index: 30;
  background-color: ${grayShades.g200};
  text-align: center;
  padding: 5px 4px;
  border-radius: 8px;
  color: ${grayShades.g800};
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
  width: max-content;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 12px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${grayShades.g200} transparent;
  }
`;

type TriggersThematicsSelectionSwitchType = {
  t: Function;
  id: number;
  dispatchSetActiveTriggersTable: Function;
  dispatchSetActiveTriggersOnlySelectedThematics: Function;
  activeTriggersOnlySelectedThematics: boolean;
  activeTriggersTable: {
    id: number;
    title: string;
    description: string;
    isDefault: boolean;
    createdBy: {
      email: string;
      firstName: string;
      lastName: string;
    };
    settings: {
      analysisAxis: string;
      calculationCriteria: string;
      itemsToDisplay: {
        name: string;
        key: string;
      };
      significanceThreshold: number;
      range: {
        startDate: string;
        endDate: string;
      };
      thematics: {
        thematic: string;
        subThematic: string;
        subSubThematic: string;
      }[];
      criteriaKeys: {
        name: string;
        values: string[];
        id: string;
        label: string;
      }[];
    };
    data: {
      color: string;
      originalColor: string;
      name: string;
      originalName: string;
      items: {
        name: string;
        originalName: string;
        percentage: number;
        value: number;
      }[];
      value: number;
      deleted: boolean;
      masked: boolean;
    }[];
    customizations: {
      color: string;
      originalColor: string;
      name: string;
      originalName: string;
      items: {
        name: string;
        originalName: string;
      }[];
      deleted: boolean;
      masked: boolean;
    }[];
  };
};

const TriggersThematicsSelectionSwitch = (props: TriggersThematicsSelectionSwitchType) => {
  const {
    t,
    id,
    dispatchSetActiveTriggersTable,
    dispatchSetActiveTriggersOnlySelectedThematics,
    activeTriggersOnlySelectedThematics,
  } = props;
  const { data: emotionalTriggersTable, loading, error, refetch } = useQuery(queryGetEmotionalTriggersTable, {
    variables: { id, applyThematicSelection: activeTriggersOnlySelectedThematics },
    fetchPolicy: 'cache-and-network',
  });

  const onChanged = () => {
    dispatchSetActiveTriggersOnlySelectedThematics(!activeTriggersOnlySelectedThematics);
    if (!activeTriggersOnlySelectedThematics) {
      refetch({
        id,
        applyThematicSelection: !activeTriggersOnlySelectedThematics,
      });
    }
    if (activeTriggersOnlySelectedThematics) {
      refetch({
        id,
        applyThematicSelection: !activeTriggersOnlySelectedThematics,
      });
    }
  };

  useEffect(() => {
    if (!loading && !error) {
      const tableToDispatch = emotionalTriggersTable?.getEmotionalTriggersTable;
      dispatchSetActiveTriggersTable(tableToDispatch);
    }
  }, [activeTriggersOnlySelectedThematics, emotionalTriggersTable?.getEmotionalTriggersTable, loading]);

  return (
    <SettingDiv>
      <LabelContainer>
        <LabelSpan>{t('Selected categories')}</LabelSpan>
        <StyledSpan role="presentation">
          <Icon icon="INTERFACE_INFO" size={20} color={grayShades.g400} />
          <TooltipThematic
            dangerouslySetInnerHTML={{
              __html: t('benchmark:Only selected categories switch hint'),
            }}
          />
        </StyledSpan>
      </LabelContainer>
      <QBooleanSwitch onChanged={() => onChanged()} value={activeTriggersOnlySelectedThematics} customMargin="0" />
    </SettingDiv>
  );
};

const mapStateToProps = ({ triggers: { activeTriggersTable, activeTriggersOnlySelectedThematics } }: any) => ({
  id: activeTriggersTable.id,
  activeTriggersTable,
  activeTriggersOnlySelectedThematics,
});

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; activeTriggersTable?: any; onlySelectedThematicsSwitch?: boolean }) => void,
) => ({
  dispatchSetActiveTriggersTable: (triggersTable: any) => {
    dispatch(setActiveTriggersTable(triggersTable));
  },
  dispatchSetActiveTriggersOnlySelectedThematics: (onlySelectedThematicsSwitch: boolean) => {
    dispatch(setActiveTriggersOnlySelectedThematics(onlySelectedThematicsSwitch));
  },
});

export default withTranslation(['button', 'triggers'])(
  connect(mapStateToProps, mapDispatchToProps)(TriggersThematicsSelectionSwitch),
);
