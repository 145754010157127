import { omit, cloneDeep } from 'lodash';

const rawOption = option =>
  option.subOptions
    ? {
      ...option,
      subOptions: option.subOptions.map(subOption =>
        omit(rawOption(subOption), '__details'),
      ),
    }
    : option;

export const rawFilterValues = values =>
  values.map(optionWithParents => {
    const newValue = cloneDeep(optionWithParents);
    newValue.option = omit(optionWithParents.option, '__details');
    return newValue.option.subOptions
      ? {
        ...newValue,
        option: rawOption(newValue.option),
      }
      : newValue;
  });
